/* 하단바 css */
/* 네비바 하단 고정 밑 세로 길이 설정 */
.wrapper {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45px;
  }
  
  /* nav태그 아래의 자식들을 수평정렬하기 위한 설정 */
  nav {
    overflow: hidden;
    border-top: 2px solid grey;
  }
  /* nav태그 아래의 div태그들을 수평정렬 및 세로길이 설정 */
  nav > div {
    /* 수평정렬, 5개의 button을 각각 20% width만큼 할당 */
    float: left;
    width: 33%;
    text-align: center;
  
    /* 세로길이 설정 */
    height: 45px;
    line-height: 45px;
  }